import React, { useEffect, useState } from "react";
import { wordlist } from "@scure/bip39/wordlists/english";
import { validateMnemonic as bip39ValidateMnemonic } from "@scure/bip39";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Dashboard } from "./componets/Dashboard";
import AppLogo from "../../../assets/img/logo.svg";

import SuiIcon from "../../../assets/img/sui.png";

import "./common.css";
import { encrypted_data } from "../../lib/helper";

// Status Functionality - default: 0, generate: 1 , scan: 2, txn: 3, completed: 4, error: 5

export function SplashWallet() {
  const getMnemonic = localStorage.getItem("wallet:mnemonic");
  const [showJoinWallet, setShowJoinWallet] = useState(false);

  return (
    <div className="app-container">
      {getMnemonic !== "" &&
        getMnemonic !== null &&
        getMnemonic !== undefined ? (
        <Dashboard />
      ) : (
        <>
          {showJoinWallet ? (
            <JoinWallet />
          ) : (
            <div className="splash-wrapper">
              <img src={AppLogo} className="app-logo" alt="BlockBolt" />
              <p>BlockBolt Payment Protocol</p>
              <button
                className="wallet-common-button"
                onClick={() => setShowJoinWallet(true)}
              >
                Let's Started
              </button>
              <div className="powered_by">
                <p>Powered by</p>
                {/* className="sui-logo"  remove this class from image below */}
                <img src={SuiIcon} alt="BlockBolt" />
              </div>
            </div>
          )}
        </>
      )}
    </div>
  );
}

function JoinWallet() {
  const [showImportWallet, setShowImportWallet] = useState(false);

  useEffect(() => { }, [showImportWallet]);
  return (
    <>
      {showImportWallet ? (
        <ImportWallet setShowImportWallet={setShowImportWallet} />
      ) : (
        <div className="wallet-wrapper">
          <img src={AppLogo} className="app-logo" alt="BlockBolt" />
          <div className="wallet-content">
            <p>
              BlockBolt is a decentralized payment protocol designed to
              facilitate fast, secure, and cost-effective transactions on the
              Base Blockchain.
            </p>
            <p>We are bringing lightening speed to transactions.</p>
          </div>
          <button
            className="wallet-common-button"
            onClick={() => setShowImportWallet(true)}
          >
            Import Your Wallet
          </button>
        </div>
      )}
    </>
  );
}

function ImportWallet(props: any) {
  const { setShowImportWallet } = props;
  const [importWords, setImportWords] = useState("");
  const [showWallet, setShowWallet] = useState(false);

  const toastMsg = (data: any) =>
    toast(data, {
      position: "bottom-left",
      autoClose: 1000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });

  const importWallet = () => {
    if (importWords !== "") {
      const TEST_MNEMONICS = importWords;
      if (
        bip39ValidateMnemonic(TEST_MNEMONICS, wordlist) &&
        (TEST_MNEMONICS.split(" ") || []).length === 12
      ) {
        localStorage.setItem("wallet:mnemonic", encrypted_data(importWords));
        localStorage.setItem("network:name", "testnet");
        localStorage.setItem(
          "network:url",
          "https://base-sepolia.g.alchemy.com/v2/Fb8rIgjDR9607bSeMkRk-j_KpRojLni4"
        );
        setShowWallet(true);
        toastMsg("Wallet imported successfully");
      } else {
        toastMsg("There is no wallet related to those mnemonic");
      }
    } else {
      toastMsg("Something went wrong");
    }
  };

  return (
    <>
      <ToastContainer />
      {showWallet ? (
        <Dashboard />
      ) : (
        <div className="import-wrapper">
          <img src={AppLogo} className="app-logo" alt="BlockBolt" />
          <p className="import-content">
            Import your existing wallet by entering the 12-word recovery phrase.
          </p>
          <textarea
            rows={3}
            className="form-control"
            placeholder="force kit end perfect concert cluster fever gap midnight chunk treat tiny"
            value={importWords}
            onChange={(e) => setImportWords(e.target.value)}
          />
          <div className="import-btn-wrapper">
            <button
              className="wallet-common-button"
              onClick={() => setShowImportWallet(false)}
            >
              Back
            </button>
            <button
              className="wallet-common-button"
              onClick={() => importWallet()}
            >
              Import Wallet
            </button>
          </div>
        </div>
      )}
    </>
  );
}
