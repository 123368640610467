import React, { useState, useContext, useRef, useEffect } from "react";
// import { JsonRpcProvider, RawSigner, TransactionBlock } from "@mysten/sui.js";
import { useWalletKit } from "@mysten/wallet-kit";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import {
  Context,
  TransactionLoader,
  TxnContext,
  // testnetConnection,
  // mainnetConnection,
  // devnetConnection,
} from "../../../../lib/helper";
// import {
//   SUI_IDO_INVESTING,
//   SUI_PACKAGE_TESTNET,
//   Boltpay,
//   getBoltpay,
// } from "../../../../lib/ido_contract";
import "../../common.css";
import CloseIcon from "../../../../../assets/img/close.svg";
// import { getSuiConfig } from "../../../../lib/sui_contract";
import { createPublicClient, parseEther } from "viem";
import { createWalletClient, http, parseGwei } from "viem";
import { mnemonicToAccount, generatePrivateKey } from "viem/accounts";

import { sepolia, bscTestnet, baseSepolia } from 'viem/chains'

interface ITxnContext {
  scanResultWebCam: any; // Replace with the correct type
  dataKeyPair: any; // Replace with the correct type
}

export function TransactionConfirmation(props: any) {
  const { setTxnDigest, setShowDetails } = props;
  const { setShowScanner, coinValue, walletNetwork, walletAddress } =
    useContext(Context);
  // const { scanResultWebCam, dataKeyPair } = useContext(TxnContext);
  const contextValue = useContext(TxnContext) as ITxnContext;
  const { scanResultWebCam, dataKeyPair } = contextValue;
  const [showLoading, setShowLoading] = useState(false);
  const [showPayLinksTxn, setShowPayLinksTxn] = useState(false);
  const [showECommerceTxn, setShowECommerceTxn] = useState(false);
  const [showIdoTxn, setShowIdoTxn] = useState(false);
  const [txnScanContent, setTxnScanContent] = useState([]);
  const refIsReloadInProgress = useRef(false);
  const [coinObjectId, setCoinObjectId] = useState("");
  // let { rpc, packageId } = getSuiConfig(walletNetwork);
  const { signAndExecuteTransactionBlock } = useWalletKit();


  const [bnbPrice, setBnbPrice] = useState(null);
  const [usdAmount, setUsdAmount] = useState(0); // Assuming 1 USD



  useEffect(() => {
    // Fetch BNB price from the API
    const url = 'https://api.etherscan.io/api?module=stats&action=bnbprice&apikey=AZU2KWAB4Y33CAZ3M9BE8YT4ZEV2EF6Q4Y';
    axios
      .get(url)
      .then((response) => {
        const price = response.data.result.ethusd; // Assuming that the BNB price is located in the ethusd field
        setBnbPrice(price);
      })
      .catch((error) => {
        // console.error('There was an error fetching the BNB price:', error);
      });
  }, []);

  const convertedAmount = bnbPrice ? bnbPrice * usdAmount : 0;


  const toastMsg = (data: any) =>
    toast(data, {
      position: "bottom-left",
      autoClose: 1000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });


  // const trigTransaction = async () => {
  //   const { config } = usePrepareSendTransaction({
  //     to: "0x56924bBf1712E53832985204C52F359Cf94b3064",
  //     value: parseEther("0.1"),
  //   });

  //   const { sendTransaction } = useSendTransaction(config);
  // }



  const blockBolt = [
    {
      inputs: [],
      stateMutability: "nonpayable",
      type: "constructor",
    },
    {
      inputs: [],
      name: "ReentrancyGuardReentrantCall",
      type: "error",
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: false,
          internalType: "string",
          name: "merchant_name",
          type: "string",
        },
        {
          indexed: false,
          internalType: "uint64",
          name: "merchant_id",
          type: "uint64",
        },
        {
          indexed: false,
          internalType: "address",
          name: "merchant_address",
          type: "address",
        },
        {
          indexed: false,
          internalType: "uint256",
          name: "amount",
          type: "uint256",
        },
      ],
      name: "MerchantEvent",
      type: "event",
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: true,
          internalType: "address",
          name: "sender",
          type: "address",
        },
        {
          indexed: true,
          internalType: "address",
          name: "recipient",
          type: "address",
        },
        {
          indexed: false,
          internalType: "uint256",
          name: "amount",
          type: "uint256",
        },
        {
          indexed: false,
          internalType: "string",
          name: "merchant_name",
          type: "string",
        },
        {
          indexed: false,
          internalType: "uint256",
          name: "order_id",
          type: "uint256",
        },
      ],
      name: "TokenTransferred",
      type: "event",
    },
    {
      inputs: [
        {
          internalType: "uint64",
          name: "",
          type: "uint64",
        },
      ],
      name: "MerchantStat",
      outputs: [
        {
          internalType: "string",
          name: "merchant_name",
          type: "string",
        },
        {
          internalType: "uint64",
          name: "merchant_id",
          type: "uint64",
        },
        {
          internalType: "address payable",
          name: "merchant_address",
          type: "address",
        },
      ],
      stateMutability: "view",
      type: "function",
    },
    {
      inputs: [
        {
          internalType: "uint64",
          name: "_merchant_id",
          type: "uint64",
        },
      ],
      name: "deleteMerchant",
      outputs: [],
      stateMutability: "nonpayable",
      type: "function",
    },
    {
      inputs: [
        {
          internalType: "uint64",
          name: "_merchant_id",
          type: "uint64",
        },
      ],
      name: "getMerchant",
      outputs: [
        {
          internalType: "string",
          name: "",
          type: "string",
        },
        {
          internalType: "uint64",
          name: "",
          type: "uint64",
        },
      ],
      stateMutability: "view",
      type: "function",
    },
    {
      inputs: [
        {
          internalType: "uint64",
          name: "_merchant_id",
          type: "uint64",
        },
      ],
      name: "isMerchantExists",
      outputs: [
        {
          internalType: "bool",
          name: "",
          type: "bool",
        },
      ],
      stateMutability: "view",
      type: "function",
    },
    {
      inputs: [],
      name: "owner",
      outputs: [
        {
          internalType: "address",
          name: "",
          type: "address",
        },
      ],
      stateMutability: "view",
      type: "function",
    },
    {
      inputs: [
        {
          internalType: "address",
          name: "_tokenAddress",
          type: "address",
        },
        {
          internalType: "address",
          name: "_to",
          type: "address",
        },
        {
          internalType: "uint256",
          name: "_amount",
          type: "uint256",
        },
      ],
      name: "sendERC20Tokens",
      outputs: [],
      stateMutability: "nonpayable",
      type: "function",
    },
    {
      inputs: [
        {
          internalType: "string",
          name: "_merchant_name",
          type: "string",
        },
        {
          internalType: "uint64",
          name: "_merchant_id",
          type: "uint64",
        },
        {
          internalType: "address payable",
          name: "_merchant_address",
          type: "address",
        },
      ],
      name: "transaction",
      outputs: [],
      stateMutability: "payable",
      type: "function",
    },
    {
      inputs: [
        {
          internalType: "address",
          name: "tokenAddress",
          type: "address",
        },
        {
          internalType: "address",
          name: "recipient",
          type: "address",
        },
        {
          internalType: "uint256",
          name: "amount",
          type: "uint256",
        },
        {
          internalType: "string",
          name: "merchant_name",
          type: "string",
        },
        {
          internalType: "uint256",
          name: "order_id",
          type: "uint256",
        },
      ],
      name: "transferToken",
      outputs: [],
      stateMutability: "nonpayable",
      type: "function",
    },
  ];

  const contractAddress = "0x0B0309747595554Cc534d36523c9f4362C9551F3"
  // const account = mnemonicToAccount(
  //   "warfare example pitch cannon tortoise smile job can patient clarify seek target",
  // );

  // const account = dataKeyPair
  // const client = createWalletClient({
  //   account,
  //   chain: sepolia,
  //   transport: http(),
  // });
  // console.log(account.address);
  // console.log(account);
  // console.log(dataKeyPair, "dataKeyPair");



  const publicClient = createPublicClient({
    chain: baseSepolia,
    transport: http()
  })

  useEffect(() => {
    const parsedData = JSON.parse(scanResultWebCam);
    const values = Object.values(parsedData);
    setTxnScanContent(values)
    // console.log(1, values);
    setShowECommerceTxn(true)

  }, [])



  const account = dataKeyPair
  // console.log(account, "account");

  // console.log(account.account.address);

  const addr = account.account.address



  const createTransaction = async (
    merchant_id: number,
    merchant_name: string
  ) => {
    setShowLoading(true); //tmp add

    const { request } = await publicClient.simulateContract({
      address: contractAddress,
      abi: blockBolt,
      addr,
      functionName: 'transaction',
      // args: ['Amazon', 42, '0x56924bBf1712E53832985204C52F359Cf94b3064'],
      args: [txnScanContent[1]
        , txnScanContent[3],
      txnScanContent[0]],

      value: parseEther(txnScanContent[2])

      // value: parseEther("0.0001")
    })
    const hash = await dataKeyPair.writeContract(request)
    // console.log(hash)
    setTxnDigest(hash);
    setShowLoading(false);
    setShowDetails(true);

  };


  // const reloadBoltpay = async () => {
  //   if (refIsReloadInProgress.current) {
  //     return;
  //   }
  //   refIsReloadInProgress.current = true;
  //   await getBoltpay(txnScanContent[3], txnScanContent[0]).then(
  //     (boltpay: Boltpay | null) => {
  //       if (!boltpay) {
  //         setTimeout(reloadBoltpay, 2000);
  //       } else {
  //         // handleClickWithIdo(boltpay);
  //       }
  //     }
  //   );
  //   refIsReloadInProgress.current = false;
  // };

  // const paymentWithIdo = () => {
  //   setShowLoading(true);
  //   reloadBoltpay();
  // };

  const managePayment = () => {
    if (coinValue > txnScanContent[2]) {
      setShowLoading(true);
      createTransaction(Math.floor(+txnScanContent[3]), "Powered by Boltpay");
    }
  };


  const TxnWithECommerce = () => {
    return (
      <>
        <div className="transaction-content">
          <p>To </p>
          <input
            readOnly
            disabled
            type="text"
            className="form-control"
            placeholder="Enter address"
            value={txnScanContent[0]}
          />
          <p>Merchant Name</p>
          <input
            readOnly
            disabled
            type="text"
            className="form-control"
            placeholder="Enter label"
            value={txnScanContent[1]}
          />
          <p>Order Id</p>
          <input
            readOnly
            disabled
            type="text"
            className="form-control"
            placeholder="Order Id"
            value={txnScanContent[3]}
          />
          <p>Amount</p>
          <input
            readOnly
            disabled
            type="text"
            className="form-control"
            placeholder="Enter amount"
            value={txnScanContent[2]}
          />
        </div>
        <button
          className="transaction-btn wallet-common-button"
          onClick={() => managePayment()}
        >
          Pay Now
        </button>
      </>
    );
  };

  return (
    <>
      <ToastContainer />
      <div className="black-bg"></div>
      <div className="common_center_parent">
        <div className="transaction-wrapper">
          {showLoading ? (
            <TransactionLoader />
          ) : (
            <>
              <img
                src={CloseIcon}
                alt="BlockBolt"
                className="transaction-close-btn"
                onClick={() => setShowScanner(false)}
              />
              {showECommerceTxn && <TxnWithECommerce />}
            </>
          )}
        </div>
      </div>
    </>
  );
}




