import React, { useContext } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import CloseIcon from "../../../../../assets/img/close.svg";
import RoundSelectedIcon from "../../../../../assets/img/round-selected.svg";
import { NetworkContext } from "../../../../lib/helper";
import "../../common.css";

// export function NavigationNetworkProfile(props: any) {
//   const { setShowNavigation } = props;
//   const { walletNetwork, setWalletNetwork, setWalletNetworkUrl } =
//     useContext(NetworkContext);

//   const toastMsg = (data: any) =>
//     toast(data, {
//       position: "bottom-left",
//       autoClose: 1000,
//       hideProgressBar: false,
//       closeOnClick: true,
//       pauseOnHover: true,
//       draggable: true,
//       progress: undefined,
//       theme: "light",
//     });

//   const manageMainnetNetwork = () => {
//     setWalletNetwork("mainnet");
//     setWalletNetworkUrl("https://wallet-rpc.mainnet.sui.io/");
//     localStorage.setItem("network:name", "mainnet");
//     localStorage.setItem("network:url", "https://wallet-rpc.mainnet.sui.io/");
//     toastMsg("Congrats!! Switched to mainnet");
//     setShowNavigation(false);
//   };

//   const manageDevnetNetwork = () => {
//     setWalletNetwork("devnet");
//     setWalletNetworkUrl("https://wallet-rpc.devnet.sui.io/");
//     localStorage.setItem("network:name", "devnet");
//     localStorage.setItem("network:url", "https://wallet-rpc.devnet.sui.io/");
//     toastMsg("Congrats!! Switched to devnet");
//     setShowNavigation(false);
//   };

//   const manageCustomNetwork = () => {
//     setWalletNetwork("testnet");
//     setWalletNetworkUrl("https://testnet.artifact.systems/sui");
//     localStorage.setItem("network:name", "testnet");
//     localStorage.setItem("network:url", "https://testnet.artifact.systems/sui");
//     toastMsg("Congrats!! Switched to testnet");
//     setShowNavigation(false);
//   };
//   return (
//     <>
//       <div className="navigation-wrapper">
//         <ToastContainer />
//         <div className="nav-header">
//           <h5>Network Settings</h5>
//           <img
//             src={CloseIcon}
//             alt="BlockBolt"
//             onClick={() => setShowNavigation(false)}
//           />
//         </div>
//         <div className="content-wrapper">
//           {walletNetwork === "mainnet" ? (
//             <div className="active_round">
//               <p onClick={() => manageMainnetNetwork()}>Sui Mainnet</p>
//               <img src={RoundSelectedIcon} />
//             </div>
//           ) : (
//             <p onClick={() => manageMainnetNetwork()}>Sui Mainnet</p>
//           )}
//           {walletNetwork === "devnet" ? (
//             <div className="active_round">
//               <p onClick={() => manageDevnetNetwork()}>Sui Devent</p>
//               <img src={RoundSelectedIcon} />
//             </div>
//           ) : (
//             <p onClick={() => manageDevnetNetwork()}>Sui Devent</p>
//           )}
//           {walletNetwork === "testnet" ? (
//             <div className="active_round">
//               <p onClick={() => manageCustomNetwork()}>Sui Testnet</p>
//               <img src={RoundSelectedIcon} />
//             </div>
//           ) : (
//             <p onClick={() => manageCustomNetwork()}>Sui Testnet</p>
//           )}
//         </div>
//       </div>
//     </>
//   );
// }


export function NavigationNetworkProfile(props: any) {
  const { setShowNavigation } = props;
  const { walletNetwork, setWalletNetwork, setWalletNetworkUrl } =
    useContext(NetworkContext);

  const toastMsg = (data: any) =>
    toast(data, {
      position: "bottom-left",
      autoClose: 1000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });

  const manageCustomNetwork = () => {
    // setWalletNetwork("testnet");
    // setWalletNetworkUrl("https://testnet.artifact.systems/sui");
    // localStorage.setItem("network:name", "testnet");
    // localStorage.setItem("network:url", "https://testnet.artifact.systems/sui");
    // // toastMsg("Congrats!! Switched to testnet");
    // setShowNavigation(false);
  };

  return (
    <>
      <div className="navigation-wrapper">
        <ToastContainer />
        <div className="nav-header">
          <h5>Network Settings</h5>
          <img
            src={CloseIcon}
            alt="BlockBolt"
            onClick={() => setShowNavigation(false)}
          />
        </div>
        <div className="content-wrapper">
          {walletNetwork === "testnet" ? (
            <div className="active_round">
              <p onClick={() => manageCustomNetwork()}>BASE Testnet</p>
              <img src={RoundSelectedIcon} />
            </div>
          ) : (
            <p onClick={() => manageCustomNetwork()}>BASE Testnet</p>
          )}
        </div>
      </div>
    </>
  );
}
