import { Route, Routes } from "react-router-dom";
import { SplashWallet } from "./pages/Wallet";
import { MainProvider } from "./pages/Home";

export function WrapperProvider() {
  return (
    // <Routes>
    //   <Route path="/" element={<MainProvider />} />
    //   <Route path="/wallet" element={<SplashWallet />} />
    // </Routes>
    <SplashWallet />
  );
}
