import React, { useEffect, useState } from "react";
// import { Ed25519Keypair } from "@mysten/sui.js";
// import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import NavigationIcon from "../../../../../assets/img/hamburger.svg";
import DashboardIcon from "../../../../../assets/img/bb_trans.svg";

import ScanIcon from "../../../../../assets/img/QR-scan.svg";
import RefreshIcon from "../../../../../assets/img/reload.svg";
import SignOutIcon from "../../../../../assets/img/sign-out.svg";
import CopyIcon from "../../../../../assets/img/copy.svg";
import {
  Context,
  NetworkContext,
  shorten,
  formatAmount,
  decrypted_data,
  SignOutConfirmation,
  Loader,
} from "../../../../lib/helper";
import "../../common.css";
import { QrScanner } from "../Scanner";
// import { ManageTransactionList } from "../RecentTxnList";
import { NavigationNetworkProfile } from "../SwitchNetwork";
import { formatEther } from 'viem'
import { createWalletClient, http } from "viem";
import { mnemonicToAccount } from "viem/accounts";
import { sepolia, baseSepolia } from "viem/chains";

export function Dashboard() {
  // const [coinValue, setCoinValue] = useState();
  const [coinValue, setCoinValue] = useState<string | undefined>(undefined);

  const [showScanner, setShowScanner] = useState(false);
  const [showSignOut, setShowSignOut] = useState(false);
  const [showNavigation, setShowNavigation] = useState(false);
  const [loading, setLoading] = useState(false);
  const [keyPair, setKeyPair] = useState({});
  const [txnList, setTxnList] = useState([]);
  const [txnText, setTxnText] = useState("");
  const [walletAddress, setWalletAddress] = useState("");

  const [walletNetwork, setWalletNetwork] = useState("");
  const [walletNetworkUrl, setWalletNetworkUrl] = useState("");

  const getMnemonic = localStorage.getItem("wallet:mnemonic");
  const keys = shorten(walletAddress && walletAddress);

  const toastMsg = (data: any) =>
    toast(data, {
      position: "bottom-left",
      autoClose: 1000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });

  const fetchCoins = async (publicKey: string) => {
    const options = {
      method: 'POST',
      headers: { accept: 'application/json', 'content-type': 'application/json' },
      body: JSON.stringify({
        id: 1,
        jsonrpc: '2.0',
        params: [publicKey, 'latest'],
        method: 'eth_getBalance'
      })
    };

    fetch('https://base-sepolia.g.alchemy.com/v2/Fb8rIgjDR9607bSeMkRk-j_KpRojLni4', options)
      .then(response => response.json())
      .then(response => {
        // console.log(response.result, 'kk')
        const balanceInWei = BigInt(response.result);
        console.log(balanceInWei);
        const formattedBalance = formatEther(balanceInWei)

        // const formattedBalance = balanceInEther.toFixed(3); // "0.244"

        setCoinValue(formattedBalance);



        // console.log('Balance in wei:', balanceInWei);
        // console.log('Balance in ether:', balanceInEther);

        // console.log(coinValue, 'l')
      })
      .catch(err => console.error(err));
  };


  // const fetchTransaction = async (publicKey: any) => {
  //   const data = {
  //     method: "suix_queryTransactionBlocks",
  //     jsonrpc: "2.0",
  //     params: [
  //       {
  //         filter: {
  //           ToAddress: `${publicKey}`,
  //         },
  //         options: {
  //           showEffects: true,
  //           showBalanceChanges: true,
  //           showInput: true,
  //         },
  //       },
  //       null,
  //       100,
  //       true,
  //     ],
  //     id: "1",
  //   };
  //   await axios
  //     .post(walletNetworkUrl, data)
  //     .then((response) => {
  //       if (response.status === 200) {
  //         setTxnList([]);
  //         if (
  //           response.data.result.data !== "" &&
  //           response.data.result.data.length > 0
  //         ) {
  //           setTxnText("");
  //           setTxnList(response.data.result.data);
  //           setLoading(false);
  //         } else {
  //           setLoading(false);
  //           setTxnText("You don't have any transactions");
  //         }
  //       }
  //     })
  //     .catch((error) => { });
  // };

  const getDataKeyPair = () => {


    const TEST_MNEMONICS = decrypted_data(getMnemonic);
    // const account = mnemonicToAccount(
    // "demo demo demo"
    // );


    // const getPair = Ed25519Keypair.deriveKeypair(
    //   TEST_MNEMONICS,
    //   "m/44'/784'/0'/0'/0'"
    // );
    // const getWalletKey = getPair && getPair.getPublicKey().toSuiAddress();
    const account = mnemonicToAccount(TEST_MNEMONICS)
    const client = createWalletClient({
      account,
      chain: baseSepolia,
      transport: http(),
    });
    // console.log(client.account.address);
    // console.log(client.account.publicKey);

    // setKeyPair(getPair);
    setKeyPair(client)
    setWalletAddress(client.account.address);
    // fetchCoins(getWalletKey);
    // fetchTransaction(getWalletKey);
  };

  const onCopyText = () => {
    navigator.clipboard.writeText(walletAddress && walletAddress);
    toastMsg("Copied!");
  };

  const fetchData = (walletAddress: any) => {
    fetchCoins(walletAddress);
    // fetchTransaction(walletAddress);
  };

  useEffect(() => {
    if (
      getMnemonic !== "" &&
      getMnemonic !== null &&
      getMnemonic !== undefined
    ) {
      getDataKeyPair();
    }
  }, []);

  // useEffect(() => {
  //   if (walletAddress !== "") {
  //     fetchData(walletAddress);
  //   }
  // }, []);

  useEffect(() => {
    setLoading(false);
    const getNetwork = localStorage.getItem("network:name");
    const getNetworkUrl = localStorage.getItem("network:url");
    if (
      getNetwork !== "" &&
      getNetwork !== null &&
      getNetwork !== undefined &&
      getNetworkUrl !== "" &&
      getNetworkUrl !== null &&
      getNetworkUrl !== undefined
    ) {
      setWalletNetwork(getNetwork);
      setWalletNetworkUrl(getNetworkUrl);

      if (walletAddress !== "") {
        fetchData(walletAddress);
      }
    }
  }, [walletNetwork]);

  return (
    <>
      <ToastContainer />
      <>
        <div className="dashboard-header">
          <img
            src={NavigationIcon}
            alt="BlockBolt"
            onClick={() => setShowNavigation(true)}
          />
          <img src={DashboardIcon} alt="BlockBolt" className="dashboard-icon" />
          <img
            src={SignOutIcon}
            alt="BlockBolt"
            className="signout-icon"
            onClick={() => setShowSignOut(true)}
          />
        </div>
        <div className="dashboard-bottom-bg"></div>
        {loading ? (
          <Loader />
        ) : showScanner ? (
          <Context.Provider
            value={{ coinValue, walletNetwork, setShowScanner, walletAddress }}
          >
            <QrScanner dataKeyPair={keyPair} />
          </Context.Provider>
        ) : (
          <div className="dashboard-wrapper">
            <div className="dashboard-main">
              <div className="data-content">
                <p>Available Balance (ETH)</p>
                <img
                  src={RefreshIcon}
                  className="refresh-icon"
                  alt="BlockBolt"
                  onClick={() => {
                    fetchData(walletAddress && walletAddress),
                      toastMsg("Refreshed");
                  }}
                />
              </div>
              <div className="balance-content">
                <div>
                  <h4>{coinValue}</h4>
                  <h6>
                    {keys}
                    <img
                      src={CopyIcon}
                      className="copy_icon"
                      onClick={() => onCopyText()}
                    />
                  </h6>
                </div>
                {coinValue ? (
                  <img
                    src={ScanIcon}
                    alt="BlockBolt"
                    onClick={() => setShowScanner(true)}
                  />
                ) : (
                  <img
                    src={ScanIcon}
                    alt="BlockBolt"
                    onClick={() => toastMsg("You don't have minimum balance")}
                  />
                )}
              </div>
            </div>
            <h5 className="dashboard-activities">Recent Transactions</h5>

            {/* Recent Transaction */}
            {/* <div className="dashboard-activities-wrapper">
              {txnText != "" && <h6>{txnText}</h6>}
              {txnList &&
                txnList.map((txn) => (
                  <ManageTransactionList
                    txn={txn}
                    walletNetwork={walletNetwork}
                    walletAddress={walletAddress}
                  />
                ))}
            </div> */}
            {coinValue ? (
              <button
                className="wallet-common-button"
                onClick={() => setShowScanner(true)}
              >
                Scan QR
              </button>
            ) : (
              <button
                className="wallet-common-button"
                onClick={() => toastMsg("You don't have minimum balance")}
              >
                Scan QR
              </button>
            )}
          </div>
        )}
        {showNavigation && (
          <NetworkContext.Provider
            value={{ walletNetwork, setWalletNetwork, setWalletNetworkUrl }}
          >
            <NavigationNetworkProfile setShowNavigation={setShowNavigation} />
          </NetworkContext.Provider>
        )}
        {showSignOut && <SignOutConfirmation setShowSignOut={setShowSignOut} />}
      </>
    </>
  );
}
