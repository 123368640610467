import React, { useState, useContext } from "react";
import { Grid } from "@material-ui/core";
import { QrReader } from "react-qr-reader";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import CloseIcon from "../../../../../assets/img/close.svg";
import { Context, TxnContext } from "../../../../lib/helper";
import "../../common.css";
import { TransactionForm } from "../Transaction";
import OP from "../Transaction/op";

export function QrScanner(props: any) {
  const { dataKeyPair } = props;
  const [scanResultWebCam, setScanResultWebCam] = useState("");
  const [showReader, setShowReader] = useState(true);
  const { setShowScanner, walletNetwork, walletAddress } = useContext(Context);

  const toastMsg = (data: any) =>
    toast(data, {
      position: "bottom-left",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });



  const handleScan = (scanData: any) => {
    setScanResultWebCam(scanData);
    setShowReader(false);
  };

  return (
    <>
      <ToastContainer />
      {showReader ? (
        <>
          <Grid
            item
            xl={4}
            lg={4}
            md={6}
            sm={12}
            xs={12}
            className="qr-scan-grid"
          >
            <QrReader
              delay={100}
              facingMode="environment"
              constraints={{
                facingMode: "environment",
              }}
              onResult={(result, error) => {
                if (!!result) {
                  handleScan(result?.text);
                }
                // if (!!error) {
                //   handleError(error);
                // }
              }}
            />
          </Grid>
          <img
            src={CloseIcon}
            alt="BlockBolt"
            id="close_QR"
            onClick={() => setShowScanner(false)}
          />
        </>
      ) : (
        <TxnContext.Provider value={{ dataKeyPair, scanResultWebCam }}>
          <TransactionForm />
          {/* <OP /> */}
        </TxnContext.Provider>
      )}
    </>
  );
}
