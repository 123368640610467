import React, { useContext } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import CopyIcon from "../../../../../assets/img/copy.svg";
import CheckIcon from "../../../../../assets/img/check-new.svg";
import { Context, shorten } from "../../../../lib/helper";
import "../../common.css";

export function TransactionDetails(props: any) {
  const { dataTxnDigest } = props;
  const { setShowScanner, walletNetwork } = useContext(Context);
  const manageNetwork =
    walletNetwork === "devnet"
      ? "devnet"
      : walletNetwork === "mainnet"
        ? "mainnet"
        : "testnet";

  const toastMsg = (data: any) =>
    toast(data, {
      position: "bottom-left",
      autoClose: 1000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });

  const onCopyText = () => {
    navigator.clipboard.writeText(dataTxnDigest && dataTxnDigest);
    toastMsg("Copied!");
  };

  return (
    <>
      <ToastContainer />
      <div className="black-bg"></div>
      <div className="common_center_parent">
        <div className="success-wrapper">
          <img src={CheckIcon} alt="BlockBolt" className="check_icon" />
          <h4>Successfully completed the transaction</h4>
          <p>Your Transaction Id</p>
          <div className="trans_id">
            <a
              href={
                "https://sepolia.basescan.org/tx/" +
                dataTxnDigest +
                "?network=" +
                manageNetwork
              }
              target="_blank"
            >
              {`${shorten(dataTxnDigest)}`}
              <img
                src={CopyIcon}
                onClick={() => onCopyText()}
                className="copy_icon"
              />
            </a>
          </div>
          <button
            className="wallet-common-button"
            onClick={() => setShowScanner(false)}
          >
            Back To Main
          </button>
        </div>
      </div>
    </>
  );
}
